(function ($) {
  $(window).load(function () {

    // owl-carousel
    $('.owl-carousel:not(.slider)').owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: false
    })

    // slider
    $('.slider:not(.offcanvas-slider):not(.reviews-slider)').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });

    // offcanvas-slider
    $('.offcanvas-slider').owlCarousel({
      items: 1,
      margin: 20,
      loop: true,
      // stagePadding: 90,

      // autoplay
      autoplay: false,
      autoplayHoverPause: true,

      // slide speed
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 1,
        },
        768: {
          items: 2
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
        1366: {
          items: 3,
        }
      }
    });

    // reviews-slider
    $('.reviews-slider').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: false,

      // drag
      mouseDrag: true
    });

    // form-sent
    setTimeout(() => {

      var locationSearch = '' + document.location.search;
      if (
        (-1 === locationSearch.indexOf('form[sent]') && -1 === decodeURIComponent(locationSearch).indexOf('form[sent]'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $headerHeight = $('.header');
      var $message = $('.form-success-message');

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $('html, body').animate({ scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30 });
    }, 500);

  });

  $(document).ready(function () {

    // sticky
    //require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');
    $(window).scroll(function(){
      var windowWidth = $(window).width();
      var $el = $('body').add('.header');
      if (windowWidth < 0) {
        $el.removeClass('sticky');
        return;
      }

      if ($(window).scrollTop() > 0) {
        $el.addClass('sticky');
      } else {
        $el.removeClass('sticky');
      }
    });

    // toggle-guestplan
    $('a.toggle-guestplan').on('click', function() {
        _gstpln.openWidget();
    });

    // quick-social
    $('div.quick-social').on('click', function (event) {
      $('div.quick-social ul').toggleClass('show');
    });

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = ""
        link.href = "#";
      }
    });

    // scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({ scrollTop: $element.offset().top }, 500);
      }
    });

    // navbar
    $(".navbar-nav .dropdown").on("shown.bs.dropdown", function (event) {
      // Get the height of the dropdown with the class "show"
      const dropdownHeight = $(this).find('.show').height();

      // Select the "header-top" section
      const headerTop = $(".header-top");

      // Get the current height of the "header-top" section
      const headerTopHeight = headerTop.height();

      // Calculate the new height for the "header-top" section
      const newHeaderTopHeight = headerTopHeight + dropdownHeight + 30;

      // Set the new height for the "header-top" section
      headerTop.height(newHeaderTopHeight);
    });

    $(".navbar-nav .dropdown").on("hide.bs.dropdown", function (event) {
      // Select the "header-top" section
      const headerTop = $(".header-top");

      // Get the current height of the "header-top" section
      const headerTopHeight = headerTop.height();

      // Set the height of the "header-top" section to its original height
      headerTop.css('height', ''); // This removes the height style property
    });

  });
})(jQuery);
